import React, { useEffect, useState } from "react";
import {
  Col,
  Modal,
  OverlayTrigger,
  Popover,
  Spinner,
  Tab,
  TabContent,
  Tabs,
  Tooltip,
} from "react-bootstrap";
import { Button, IconButton } from "@mui/material";
import { Anchor, Column, StatusCard } from "views/authenticated/styled";
import {
  handleBgColor,
  handleBgColorMarker,
  swedishIt,
} from "../providers/StylingFunc";
import { getWorkerOrderInfo } from "Services/Api";
import Pdf from "resources/images/svg/pdf.svg";
import Dxf from "resources/images/svg/dxf.svg";
import Zip from "resources/images/svg/zip.svg";
import AnyFile from "resources/images/svg/file.svg";
import CrossAlt from "resources/images/svg/cross-black.svg";
import { userData } from "DTO/userWorkOrderListDto";
import { HorizontalFlex, Sect, Image, VerticalFlex } from "pandora/styled";
import { turquoise } from "resources/newColors";
import moment from "moment";
import DisplayLogs from "./LogsDisplay";
import DisplayFiles from "./FilesDisplay";
import DisplayImages from "./ImagesDisplay";
import MapGl from "./MapGl";
import { darkGreen, veryLightGrey } from "resources/newColors";
import {
  imageData,
  MetricsType,
  userWorkOrderListDto,
} from "DTO/userWorkOrderListDto";
import { useHistory } from "react-router";
import CloseIcon from "@mui/icons-material/Close";

const map_api = process.env.REACT_APP_MAPBOX_KEY;

export default function CaseModal(props) {
  const [activeCaseData, setActiveCaseData] = useState<userData>();
  const [loading, setLoading] = useState(true);
  const [loadingTab, setLoadingTab] = useState(true);
  const [key, setKey] = useState("lease");
  const [viewport, setViewport] = useState({
    latitude: 59.334591,
    longitude: 18.06324,
    zoom: 8,
    width: "100%",
    height: "45vh",
    margin: "auto",
  });
  const history = useHistory();
  const [selectedCaseId, setSelectedCaseId] = useState<userData>();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [imageIndex, setImageIndex] = React.useState(0);
  const [selectedUserImage, setSelectedUserImage] = useState<
    imageData[] | []
  >();
  const [selectedUserFiles, setSelectedUserFiles] = useState<
    string[] | undefined
  >();
  const [showError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [caseLogs, setCaseLogs] = useState([]);
  const [mapPopUp, setMapPopUp] = useState(false);
  const [networkCoords, setNetworkCoords] = useState([]);
  const [networkImages, setNetworkImages] = useState([]);
  const [polygonFromGeoJson, setPolygonFromGeoJson] = useState("");
  const [polygonFromCaseLocation, setPolygonFromCaseLocation] = useState<any>();
  const [markerCenter, setMarkerCenter] = useState({
    latitude: 0,
    longitude: 0,
  });
  const [activeActive, setActiveActive] = useState<number>();
  const displayData = props.allOrderData;
  const [show, setShow] = useState(false);

  useEffect(() => {
    fetchCaseData();
  }, []);

  useEffect(() => {
    setLoadingTab(true);
    fetchCaseData();
    setSelectedUserData();
  }, [key]);

  const fetchCaseData = async () => {
    try {
      const caseData = await getWorkerOrderInfo(props.activeCaseId);
      if (caseData) {
        setActiveCaseData(caseData.Data);
        setLoading(false);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const handleFileClick = async (fileURL: string) => {
    try {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.href = fileURL;
      a.target = "_blank";
      a.download = "file";
      a.click();
    } catch (error) {}
  };

  const setSelectedUserData = async () => {
    setLoadingTab(true);
    setSelectedUserImage([]);
    setSelectedUserFiles(undefined);
    //  setSelectedCaseId(activeCaseData);
    try {
      const images = await getWorkerOrderInfo(props.activeCaseId);

      let allCaseLogs = images ? images?.Data?.Logs : [];
      let geomatry = images ? images?.Data?.Geometry : [];
      setPolygonFromCaseLocation(geomatry);
      setCaseLogs(allCaseLogs);
      const newViewport = {
        ...viewport,
        latitude: images?.Data?.Center?.WGS84[0][0],
        longitude: images?.Data?.Center?.WGS84[0][1],
        zoom: 17,
      };
      setViewport(newViewport);
      setMarkerCenter({
        latitude: images?.Data?.Center?.WGS84[0][0],
        longitude: images?.Data?.Center?.WGS84[0][1],
      });
      if (
        images &&
        images?.Data &&
        images?.Data &&
        images?.Data?.network_coords
      ) {
        setNetworkCoords(images?.Data?.network_coords);
      }
      if (
        images &&
        images?.Data &&
        images?.Data &&
        images?.Data?.network_urls
      ) {
        setNetworkImages(images?.Data?.network_urls);
      }
      if (images?.Data?.Document) {
        setSelectedUserFiles(images?.Data?.Document);
      } else {
        setSelectedUserFiles(undefined);
      }
      if (images?.Data?.ImageData?.length) {
        setSelectedUserImage(images?.Data?.ImageData);
      } else {
        setSelectedUserImage([]);
      }
      setTimeout(() => {
        setLoadingTab(false);
      }, 2500);
    } catch (e: any) {
      if (e?.response?.status === 401) {
        localStorage.removeItem("token");
        history.push("/");
      }
      setSelectedUserImage([]);
      setSelectedUserFiles(undefined);
    }
  };

  const renderTooltip = (props) => (
    <Tooltip
      id={`${
        props.type && props.type === "unassign"
          ? "button-tooltip2"
          : "button-tooltip"
      }`}
      {...props}
    >
      <div style={{ backgroundColor: "black" }}>{props.id}</div>
      <div
        style={{
          backgroundColor: props.status ? handleBgColor(props.status) : "#FFF",
        }}
      >
        <div>
          <strong>Name : </strong>
          {props.name}
        </div>
      </div>
    </Tooltip>
  );

  const CaseInfo = () => {
    return (
      <>
        <Tabs
          activeKey={key}
          onSelect={(k) => {
            setKey(k);
            if (k === "lease") {
              setSelectedUserData();
            }
          }}
          style={{
            width: "100%",
            display: "flex",
            marginTop: 5,
          }}
        >
          <Tab eventKey="lease" title="Karta">
            <TabContent
              style={{
                borderTop: `1px solid ${veryLightGrey}`,
              }}
            >
              <Col style={{ fontSize: "14px" }}>
                <>
                  <HorizontalFlex css="font-size: 13px; margin-top: 20px">
                    <Sect css="font-weight: 600; flex: 2">Benämning</Sect>
                    <Sect css="flex: 4">{activeCaseData.CaseName}</Sect>
                  </HorizontalFlex>
                  <HorizontalFlex css="font-size: 13px; margin-top: 20px">
                    <Sect css="font-weight: 600; flex: 2">Plats</Sect>
                    <Sect css="flex: 4">{activeCaseData.Location}</Sect>
                  </HorizontalFlex>
                  <HorizontalFlex css="font-size: 13px; margin-top: 20px">
                    <Sect css="font-weight: 600; flex: 2">Startdatum</Sect>
                    <Sect css="flex: 4; padding-left:4px">
                      {moment(activeCaseData.StartDate).format("DD MMM YYYY")}
                    </Sect>
                  </HorizontalFlex>
                  <HorizontalFlex css="font-size: 13px; margin-top: 20px">
                    <Sect css="font-weight: 600; flex: 2">Förfrågare</Sect>
                    <Sect
                      css={`
                        flex: 4;
                      `}
                    >
                      <Anchor
                        hoverColor={turquoise}
                        color={turquoise}
                        css={`
                          color: ${turquoise};
                          text-decoration: underline;
                          padding-left: 4px;
                        `}
                        href={`mailto:${activeCaseData.IquirerEmail}`}
                      >
                        {activeCaseData.IquirerEmail}
                      </Anchor>
                    </Sect>
                  </HorizontalFlex>
                  <HorizontalFlex css="font-size: 13px; margin-top: 20px">
                    {activeCaseData.InquiryType === "ProjectInquiry" ? (
                      <>
                        <Sect css="font-weight: 600; flex: 2">Fil format</Sect>
                        {activeCaseData.GisFileFormat ? (
                          <Sect
                            css="flex: 4; text-align: left;
                justify-content: flex-start;
                padding-left:4px"
                          >
                            {activeCaseData.GisFileFormat}(
                            {activeCaseData.FileCoordinateSystem})
                          </Sect>
                        ) : (
                          <Sect
                            css="flex: 4; text-align: left;
                justify-content: flex-start;
                padding-left:4px"
                          >
                            {" "}
                            -{activeCaseData.GisFileFormat}
                          </Sect>
                        )}
                      </>
                    ) : (
                      <>
                        <Sect css="font-weight: 600; flex: 2">Tekniker</Sect>
                        <Sect
                          css="flex: 4; text-align: left;
                justify-content: flex-start;
                padding-left:4px"
                        >
                          {activeCaseData.Assignee ? (
                            <Sect
                              css="flex: 4; text-align: left;
        justify-content: flex-start;
        padding-left:4px"
                            >
                              <Anchor
                                hoverColor={turquoise}
                                color={turquoise}
                                css={`
                                  color: ${turquoise};
                                  text-decoration: underline;
                                `}
                                href={`mailto:${activeCaseData.AssigneeEmail}`}
                              >
                                {" "}
                                {activeCaseData.Assignee}
                              </Anchor>
                            </Sect>
                          ) : (
                            <Sect
                              css="flex: 4; text-align: left;
      justify-content: flex-start;
      padding-left:4px"
                            >
                              Otilldelad
                            </Sect>
                          )}
                        </Sect>
                      </>
                    )}
                  </HorizontalFlex>
                  {activeCaseData.number_of_sites && (
                    <HorizontalFlex css="text-align: left; font-size: 13px; margin-top: 20px">
                      <Sect css="font-weight: 600; flex: 2">Antal område</Sect>
                      <Sect
                        css="flex: 4; text-align: left;
        justify-content: flex-start;
        padding-left:4px"
                      >
                        {" "}
                        {activeCaseData.number_of_sites}
                      </Sect>
                    </HorizontalFlex>
                  )}
                  <HorizontalFlex css="font-size: 13px; margin-top: 20px">
                    <Sect css="font-weight: 600; flex: 2">Ärende typ</Sect>
                    <Sect css="flex: 4">
                      {swedishIt("", activeCaseData.InquiryType)}
                    </Sect>
                  </HorizontalFlex>
                  <HorizontalFlex css="font-size: 13px; margin-top: 20px">
                    <Sect css="font-weight: 600; flex: 2">Arbetsmetod</Sect>
                    {activeCaseData.WorkMethods ? (
                      <Sect css="flex: 4">{activeCaseData.WorkMethods}</Sect>
                    ) : (
                      <Sect css="flex: 4">-</Sect>
                    )}
                  </HorizontalFlex>
                  <HorizontalFlex css="font-size: 13px; margin-top: 20px">
                    <Sect css="font-weight: 600; flex: 2">Filer</Sect>
                    <Sect css="flex: 4; justify-content: start; ">
                      {activeCaseData.Document &&
                      activeCaseData.Document.length ? (
                        activeCaseData.Document.map(
                          (item: string, index: number) => (
                            <OverlayTrigger
                              placement="auto"
                              overlay={
                                <Tooltip id={`tooltip-auto`}>
                                  {activeCaseData.WOCaseId +
                                    "-" +
                                    item.split("/").pop()}
                                </Tooltip>
                              }
                            >
                              <Image
                                onClick={() => {
                                  if (item) {
                                    handleFileClick(item);
                                  } else {
                                  }
                                }}
                                width={"30px"}
                                src={
                                  item.includes("pdf")
                                    ? Pdf
                                    : item.includes("dxf")
                                    ? Dxf
                                    : item.includes("zip")
                                    ? Zip
                                    : AnyFile
                                }
                                css="margin-right: 15px;"
                              />
                            </OverlayTrigger>
                          )
                        )
                      ) : (
                        <p>Inga filer</p>
                      )}
                    </Sect>
                  </HorizontalFlex>
                </>
                <div>
                  <div>
                    <Modal
                      show={show}
                      onHide={() => setShow(false)}
                      className="mapZoomModel"
                    >
                      <Modal.Header className="border-0">
                        {" "}
                        <CloseIcon
                          className="bg-light"
                          onClick={() => setShow(false)}
                        />
                      </Modal.Header>
                      <Modal.Body>
                        {viewport && key === "lease" && (
                          <MapGl
                            activeCaseId={props.activeCaseId}
                            // viewport={viewportModel}
                            // mapApi={map_api}
                            // setViewport={setViewportModel}
                            // setMapPopUp={setMapPopUp}
                            // polygonFromGeoJson={polygonFromGeoJson}
                            // polygonFromCaseLocation={
                            //   polygonFromCaseLocation
                            // }
                            // tableData={displayData}
                            // renderTooltip={renderTooltip}
                            // networkUrls={networkImages}
                            // handleBgColorMarker={handleBgColorMarker}
                            // networkCoords={networkCoords}
                            // selectedCaseId={selectedCaseId}
                            showBigMap={setShow}
                            show={show}
                          />
                        )}
                      </Modal.Body>
                    </Modal>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    border: "1px solid #eee",
                    margin: "30px 0",
                  }}
                >
                  {!loadingTab && viewport && key === "lease" ? (
                    <MapGl
                      activeCaseId={props.activeCaseId}
                      // viewport={viewport}
                      // mapApi={map_api}
                      // setViewport={''}
                      // setMapPopUp={setMapPopUp}
                      // polygonFromGeoJson={polygonFromGeoJson}
                      // polygonFromCaseLocation={polygonFromCaseLocation}
                      // tableData={displayData}
                      // renderTooltip={renderTooltip}
                      // networkUrls={networkImages}
                      // handleBgColorMarker={handleBgColorMarker}
                      // networkCoords={networkCoords}
                      // selectedCaseId={activeCaseData}
                      showBigMap={setShow}
                      show={show}
                    />
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ minHeight: "250px" }}
                    >
                      {" "}
                      <Spinner
                        style={{ margin: "auto" }}
                        variant="dark"
                        animation="border"
                        role="status"
                      ></Spinner>{" "}
                    </div>
                  )}
                </div>
              </Col>
            </TabContent>
          </Tab>
          <Tab eventKey="images" title="Bilder">
            <TabContent
              style={{
                borderTop: `1px solid ${veryLightGrey}`,
              }}
            >
              <DisplayImages
                setIsOpen={setIsOpen}
                setImageIndex={setImageIndex}
                imageProps={selectedUserImage}
              />
            </TabContent>
          </Tab>
          <Tab eventKey="files" title="Filer">
            <TabContent
              style={{
                borderTop: `1px solid ${veryLightGrey}`,
              }}
            >
              {
                <DisplayFiles
                  selectedUserFiles={selectedUserFiles}
                  setError={setError}
                  setErrorMessage={setErrorMessage}
                  handleFileClick={handleFileClick}
                  caseID={activeCaseData?.WOCaseId}
                />
              }
            </TabContent>
          </Tab>
          <Tab eventKey="log" title="Logg">
            <TabContent
              style={{
                borderTop: `1px solid ${veryLightGrey}`,
                overflowX: "auto",
              }}
            >
              <DisplayLogs caseLogs={caseLogs} />
            </TabContent>
          </Tab>
        </Tabs>
      </>
    );
  };

  return (
    <>
      {activeCaseData && (
        <Modal
          animation
          scrollable
          backdrop
          {...props}
          size="l"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          {loading ? (
            <div style={{ paddingLeft: "50%" }}>
              <Spinner
                style={{ float: "left" }}
                animation="border"
                role="status"
              ></Spinner>
            </div>
          ) : (
            <>
              <Modal.Header
                style={{
                  display: "flex",
                }}
              >
                <Modal.Title
                  style={{ flex: 1 }}
                  id="contained-modal-title-vcenter"
                >
                  <div>
                    <Sect
                      css={`
                        color: ${turquoise};
                        margin-right: 5px;
                        width: auto;
                      `}
                    >
                      #
                    </Sect>
                    {props.activeCaseId}
                  </div>
                </Modal.Title>
                <StatusCard
                  css={`
                    text-align: center;
                    right: 0px;
                    background-color: ${handleBgColor(activeCaseData.Status)};
                    self-align: right;
                  `}
                >
                  {swedishIt(activeCaseData.Status, activeCaseData.InquiryType)}
                </StatusCard>
                <IconButton onClick={() => props.setModalShow(false)}>
                  <CloseIcon />
                </IconButton>
              </Modal.Header>
              <Modal.Body style={{ height: "550px", overflowY: "auto" }}>
                <CaseInfo />
              </Modal.Body>
            </>
          )}
        </Modal>
      )}
    </>
  );
}
