import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { userWorkOrderListDto } from "DTO/userWorkOrderListDto";
import { getWorkOrderList } from "Services/Api";

type SessionData = {
  workOrderListData: userWorkOrderListDto[];
};
export const Ctx = createContext<SessionData | undefined>(undefined);

export const useCtx = () => {
  const ctx = useContext(Ctx);
  if (ctx === undefined) {
    throw new Error("Context is undefined");
  }
  return ctx;
};
export const SessionDataContext = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [allWorkorders, setAllWorkorders] = useState<
    Array<userWorkOrderListDto>
  >([]);

  const tenantsWorkOrders = useMemo(() => {
    if (!localStorage.getItem("token")) return [];
    return (
      allWorkorders
        //sorting table according to workorder id
        .sort(function (a, b) {
          return b.WOCaseId.localeCompare(a.WOCaseId);
        })
    );
  }, [allWorkorders, localStorage]);
  useEffect(() => {
    function checkStorage(e: StorageEvent) {
      if (e.key === "token") setAllWorkorders([]);
    }
    window.addEventListener("storage", checkStorage);
    return () => {
      window.removeEventListener("storage", checkStorage);
    };
  }, []);
  useEffect(() => {
    fetchAllWorkOrders();

    return () => {
      setAllWorkorders([]);
    };
  }, []);
  const fetchAllWorkOrders = async () => {
    setAllWorkorders((await getWorkOrderList()) ?? []);
  };
  const value = useMemo(
    () => ({ workOrderListData: tenantsWorkOrders }),
    [tenantsWorkOrders]
  );
  return <Ctx.Provider value={value}>{children}</Ctx.Provider>;
};
