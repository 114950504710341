import { useState } from "react";
import "./App.css";
import LoginView from "views/unauthenticated/LoginView";
import Layout from "views/authenticated/Layout";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import AuthContext, {
  AuthContextInterface,
  AuthValue,
} from "context/AuthContext";
import { SessionDataContext } from "context/SessionDataContext";

function App() {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.defaults.headers.post["Content-Type"] = "application/json";
  if (localStorage.getItem("token")) {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
  }

  const [isUserLogin, setIsUserLogin] = useState(
    Boolean(localStorage.getItem("token"))
  );

  const setIsUserLoginTrueORFalse = (param: string | boolean) => {
    if (param) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${param}`;
    }
    setIsUserLogin(Boolean(param));
  };

  const [auth, setAuth] = useState<AuthValue>({
    name: "",
    profile_pic: "",
    org: "",
  });

  const logout = () => {
    setIsUserLogin(false);
    localStorage.removeItem("token");
  };
  return (
    <AuthContext.Provider value={{ auth, setAuth, logout }}>
      <div
        className="App "
        style={{
          fontFamily: "Montserrat", //Global Fonts
        }}
      >
        <section>
          {isUserLogin ? (
            <SessionDataContext>
              <Layout setIsUserLoginTrueORFalse={setIsUserLoginTrueORFalse} />
            </SessionDataContext>
          ) : (
            <LoginView setIsUserLoginTrueORFalse={setIsUserLoginTrueORFalse} />
          )}
        </section>
      </div>
    </AuthContext.Provider>
  );
}

export default App;
