// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-link {
  color: #61dafb;
}

*::-webkit-scrollbar {
  width: 13px;
}

*::-webkit-scrollbar-track {
  background: none;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(22, 25, 29);
  border: 4px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: rgba(107, 107, 107, 0.4);
}

iframe{
  position: relative !important;
}

input[type=file] + label {
  border : 1px solid #06B4C8;
  border-radius: 10px;
  background-color: #06B4C8;
  color: #fff;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  cursor: pointer;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iCAAiC;EACjC,6BAA6B;EAC7B,kBAAkB;EAClB,4BAA4B;AAC9B;;AAEA;EACE,0CAA0C;AAC5C;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,0BAA0B;EAC1B,mBAAmB;EACnB,yBAAyB;EACzB,WAAW;EACX,eAAe;EACf,oBAAoB;EACpB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,KAAK,+BAA+B,EAAE;EACtC,OAAO,iCAAiC,EAAE;AAC5C;;AAEA;EACE,KAAK,uBAAuB,EAAE;EAC9B,OAAO,yBAAyB,EAAE;AACpC","sourcesContent":[".App {\n  text-align: center;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n*::-webkit-scrollbar {\n  width: 13px;\n}\n\n*::-webkit-scrollbar-track {\n  background: none;\n}\n\n*::-webkit-scrollbar-thumb {\n  background-color: rgb(22, 25, 29);\n  border: 4px solid transparent;\n  border-radius: 8px;\n  background-clip: padding-box;\n}\n\n*::-webkit-scrollbar-thumb:hover {\n  background-color: rgba(107, 107, 107, 0.4);\n}\n\niframe{\n  position: relative !important;\n}\n\ninput[type=file] + label {\n  border : 1px solid #06B4C8;\n  border-radius: 10px;\n  background-color: #06B4C8;\n  color: #fff;\n  font-size: 1rem;\n  padding: 0.5rem 1rem;\n  margin-top: 1rem;\n  cursor: pointer;\n}\n\n@-webkit-keyframes spin {\n  0% { -webkit-transform: rotate(0deg); }\n  100% { -webkit-transform: rotate(360deg); }\n}\n\n@keyframes spin {\n  0% { transform: rotate(0deg); }\n  100% { transform: rotate(360deg); }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
