import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faSignOutAlt,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import { ReactComponent as MainLogo } from "resources/images/svg/new-logo.svg";
import { ReactComponent as ListView } from "resources/images/svg/list-view.svg";
import { ReactComponent as ColumnView } from "resources/images/svg/column-view.svg";
import { ReactComponent as BlockView } from "resources/images/svg/block-view.svg";

import {
  Button,
  Flex,
  Logo,
  LogoContainer,
  VerticalFlex,
  HorizontalFlex,
} from "../pandora/styled";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  Aside,
  SidebarButtonContainer,
  SidebarButtonSubContainer,
  SidebarButton,
  SidebarButtonLabel,
} from "./styles/sidebar-style";

import { currentUser } from "views/authenticated/Layout";
import "./sidebar.scss";

export interface SidebarProps {
  collapse: boolean;
  setCollapse: (v: boolean) => void;
  listType?: string;
  userDetails?: currentUser;
  setIsUserLoginTrueORFalse?: (v: boolean) => void;
}

const faChevronRightIcon = faChevronRight as IconProp;
const faChevronLeftIcon = faChevronLeft as IconProp;
const faSignOutAltIcon = faSignOutAlt as IconProp;
const Sidebar = ({
  collapse,
  setCollapse,

  setIsUserLoginTrueORFalse,
}: SidebarProps) => {
  const history = useHistory();
  const location = useLocation();
  const [selectedView, setSelectedView] = useState<string>("block");
  const [loggedUser, setLoggedUser] = useState({
    currentUser: "",
    currentUsername: "",
    tenant: "",
    tenantID: "",
  });

  useEffect(() => {
    if (localStorage.getItem("token")) {
      const user1 = {
        currentUser: localStorage.getItem("user_name"),
        currentUsername: localStorage.getItem("username"),
        tenant: localStorage.getItem("user_org"),
        tenantID: localStorage.getItem("tenant_id"),
      };
      setLoggedUser(user1);
    }
  }, []);

  useEffect(() => {
    setSelectedView(location.pathname);
  }, [location]);

  const handleLogOut = () => {
    localStorage.removeItem("token");
    setIsUserLoginTrueORFalse(false);
    history.push("/");
  };

  return (
    <React.Fragment>
      <Aside
        collapse={collapse}
        className={collapse ? "sidebar" : "sidebar sidebar--active"}
      >
        <div>
          <div className="sidebar__header">
            <Link className="sidebar__logo" to={"/oversikt"}>
              <MainLogo />
            </Link>
            {!collapse && (
              <Button
                onClick={() => setCollapse(!collapse)}
                color="transparent"
                hoverColor="transparent"
                css={"padding: 5px; outline: none; border: none"}
                style={{
                  height: "20px",
                  padding: 0,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={collapse ? faChevronRightIcon : faChevronLeftIcon}
                  size="lg"
                />
              </Button>
            )}
          </div>

          <div className="sidebar__menu">
            <SidebarButtonContainer
              to="/oversikt"
              collapse={collapse}
              className="menuItem"
            >
              <SidebarButtonSubContainer
                className={
                  selectedView === "/oversikt"
                    ? "menuItem__link activeMenu"
                    : "menuItem__link"
                }
                selected={selectedView === "/oversikt" ? true : false}
              >
                <BlockView />
                {!collapse && <SidebarButtonLabel>Översikt</SidebarButtonLabel>}
              </SidebarButtonSubContainer>
            </SidebarButtonContainer>
            <SidebarButtonContainer
              to="/"
              collapse={collapse}
              className="menuItem"
            >
              <SidebarButtonSubContainer
                selected={selectedView === "/" ? true : false}
                className={
                  selectedView === "/"
                    ? "menuItem__link activeMenu"
                    : "menuItem__link"
                }
              >
                <ListView />
                {!collapse && <SidebarButtonLabel>Ärenden</SidebarButtonLabel>}
              </SidebarButtonSubContainer>
            </SidebarButtonContainer>
            <SidebarButtonContainer
              to="/organisation"
              collapse={collapse}
              className="menuItem"
            >
              <SidebarButtonSubContainer
                selected={selectedView === "/organisation" ? true : false}
                className={
                  selectedView === "/organisation"
                    ? "menuItem__link activeMenu"
                    : "menuItem__link"
                }
              >
                <ColumnView />
                {!collapse && (
                  <SidebarButtonLabel>Organisation</SidebarButtonLabel>
                )}
              </SidebarButtonSubContainer>
            </SidebarButtonContainer>
          </div>
          {collapse && (
            <Button
              onClick={() => setCollapse(!collapse)}
              color="transparent"
              hoverColor="transparent"
              css={"padding: 5px; outline: none; border: none"}
            >
              <FontAwesomeIcon
                icon={collapse ? faChevronRightIcon : faChevronLeftIcon}
                size="lg"
              />
            </Button>
          )}
        </div>
        <div>
          <VerticalFlex css="flex: 1; justify-content: flex-end; align-items: center; width: 100%">
            <HorizontalFlex
              onClick={handleLogOut}
              css={`
                cursor: pointer;
                align-items: center;
                padding: 10px;
                background-color: rgba(0, 0, 0, 0.29);
                width: ${collapse ? "auto" : "100%"};
              `}
            >
              <FontAwesomeIcon
                color="white"
                size="1x"
                icon={faSignOutAltIcon}
              />
              {collapse ? null : (
                <HorizontalFlex
                  css="color: white; font-size: 13px; font-weight: 600; margin-left: 5px;"
                  onClick={() => {
                    handleLogOut();
                  }}
                >
                  {loggedUser?.currentUser} <br />
                  Logga ut
                </HorizontalFlex>
              )}
            </HorizontalFlex>
          </VerticalFlex>
        </div>
      </Aside>
    </React.Fragment>
  );
};

export default Sidebar;
