/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useMemo, useState } from "react";
import { useTable, useFilters } from "react-table";
import {
  handleBgColor,
  handleGraphLanguages,
  swedishIt,
} from "../../providers/StylingFunc";
import moment from "moment";
import "moment/locale/sv";
import Loader from "react-loader-spinner";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
  Container,
  Tabs,
  Tab,
  TabContent,
  Tooltip,
  Dropdown,
  Modal,
  Form,
  OverlayTrigger,
} from "react-bootstrap";

import {
  SearchInputContainer,
  SearchInput,
  PrefixIcon,
} from "../../components/styles/nav-style";
import Search from "resources/images/svg/search.svg";
import XlsxIcon from "resources/images/svg/xlsx.svg";

import Alert from "components/Alert";

import DisplayImages from "components/ImagesDisplay";
import DisplayFiles from "components/FilesDisplay";
import DisplayLogs from "components/LogsDisplay";
import ImageSlider from "components/ImageSlider";
import MapGl from "components/MapGl";

import { HorizontalFlex, VerticalFlex } from "pandora/styled";
import { darkGreen, veryLightGrey } from "resources/newColors";
import CrossAlt from "resources/images/svg/cross-black.svg";
// import Carret from "resources/images/svg/carret.svg";
import {
  Table,
  HeaderSection,
  TableBody,
  TableHead,
  TableRow,
  TableData,
  // DropdownItem,
  IconButton,
  Column,
  Row,
  PaginationDiv,
  PaginationItem,
  DownloadButton,
  Button,
  FileLoader,
} from "./styled";
import {
  getMetrics,
  getWorkerOrderInfo,
  getWorkOrderList,
  getWorkOrderListbyCaseStatus,
  getWorkOrderListbyCaseType,
  getWorkOrderListPaged,
  updateWorkOrderStatus,
} from "Services/Api";
import { useHistory } from "react-router";
import {
  imageData,
  MetricsType,
  userWorkOrderListDto,
} from "DTO/userWorkOrderListDto";
import LeaseInfo from "components/LeaseInfo";
import { Box, Grid } from "@mui/material";
import MapModal from "components/MapModal";
import WorkOrderTableBody from "components/WorkOrderTableBody";
import { useCtx } from "context/SessionDataContext";
import { ca, fi } from "date-fns/locale";
//import AuthContext from "context/AuthContext";

const filters = [
  {
    label: "Alla Ärenden",
    value: "all",
  },
  {
    label: "Tilldelade",
    value: "assigned",
  },
  {
    label: "Otilldelade",
    value: "unassigned",
  },
  {
    label: "Avvikelser",
    value: "issue",
  },
  {
    label: "Avslutade ärenden",
    value: "done",
  },
  {
    label: "Projekteringar",
    value: "project",
  },
  {
    label: "Ledningsanvisningar",
    value: "cable indication",
  },
];

const customStyles = {
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    border: "none",
    overflow: "hidden",
    background: "rgba(0,0,0,0.29)",
  },
};

type workOrderProps = {
  setIsUserLoginTrueORFalse: (val: boolean) => boolean;
};

// type SelectType = {
//   label: string,
//   value: string
// }

const WorkOrders = ({ setIsUserLoginTrueORFalse }: workOrderProps) => {
  const [activeActive, setActiveActive] = useState<number | string>();
  // const [assignees, setAssignees] = useState([]);
  const [tableData, setTableData] = useState([]);
  const localMetrics = localStorage.getItem("metrics");
  const [metrics, setMetrics] = useState<MetricsType>(JSON.parse(localMetrics));
  const [filteredData, setFilteredData] = useState<any[] | null>();
  //const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedCaseId, setSelectedCaseId] = useState<userWorkOrderListDto>();
  const [mapPopUp, setMapPopUp] = useState(false);
  const [selectedUserImage, setSelectedUserImage] = useState<
    imageData[] | []
  >();
  const [selectedUserFiles, setSelectedUserFiles] = useState<
    string[] | undefined
  >();
  // const [currentLoggedInUserDetails, setCurrentLoggedInUserDetails] =
  //   useState<any>();
  const [polygonFromCaseLocation, setPolygonFromCaseLocation] = useState<any>();
  const [showSpinner, setShowSpinner] = useState(true);
  const currentFilter = localStorage.getItem("filter")
    ? localStorage.getItem("filter")
    : "all";
  const [activeFilter, setActiveFilter] = useState<string>(currentFilter);

  const [polygonFromGeoJson, setPolygonFromGeoJson] = useState("");
  const [key, setKey] = useState("lease");
  const [showTekis, setShowTekis] = useState<number>();

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [imageIndex, setImageIndex] = React.useState(0);
  const history = useHistory();
  const [viewport, setViewport] = useState({
    latitude: 59.334591,
    longitude: 18.06324,
    zoom: 8,
    width: "100%",
    height: "45vh",
    margin: "auto",
  });

  const [viewportModel, setViewportModel] = useState({
    latitude: 59.334591,
    longitude: 18.06324,
    zoom: 8,
    width: "100%",
    height: "45vh",
    margin: "auto",
  });

  const [markerCenter, setMarkerCenter] = useState({
    latitude: 0,
    longitude: 0,
  });
  //useState case logs
  const [caseLogs, setCaseLogs] = useState([]);
  const [networkCoords, setNetworkCoords] = useState([]);
  const [networkImages, setNetworkImages] = useState([]);

  // regards => search case by id
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [paginatedData, setPaginatedData] = useState<userWorkOrderListDto[]>(
    []
  );
  const { workOrderListData } = useCtx();

  const removeShowTeki = (e) => {
    const id = e.target.id;
    if (id.indexOf("showteki") === -1 && showTekis != null) setShowTekis(null);
  };

  useEffect(() => {
    // setActiveFilter(localStorage.getItem('filter'));
    // localStorage.getItem('filter') ? setActiveFilter(localStorage.getItem('filter')) : setActiveFilter('all');
    document.addEventListener("click", removeShowTeki);
    return function clear() {
      document.removeEventListener("click", removeShowTeki);
    };
  });

  //const { auth } = useContext(AuthContext);

  const [pageSize, setPageSize] = useState(20);
  const [numPages, setNumPages] = useState(0);
  const [activeActivePage, setActiveActivePage] = useState(1);
  const [numberOfCases, setNumberOfCases] = useState<number>();

  //******************* */
  const [showExport, setShowExport] = useState(false);
  const [exportFrom, setExportFrom] = useState("");
  const [exportTo, setExportTo] = useState("");
  const [exportChoices, setExportChoices] = useState<{
    cable: boolean;
    project: boolean;
    all: boolean;
  }>({ cable: true, project: true, all: false });
  const [downloadingLoader, setDownloadingLoader] = useState(false);
  const [show, setShow] = useState(false);
  /***************************** */

  useEffect(() => {
    let cancelled = false;
    const getNumberOfPages = async () => {
      try {
        const metricsData = await getMetrics();
        if (cancelled) return;
        setMetrics(metricsData);
        const n =
          metricsData.CableCount -
          metricsData.AssignedCount -
          metricsData.UnassignedCount -
          metricsData.IssueCount;
        localStorage.setItem(
          "metrics",
          JSON.stringify({
            TotalCount: metricsData.TotalCount,
            UnassignedCount: metricsData.UnassignedCount,
            AssignedCount: metricsData.AssignedCount,
            IssueCount: metricsData.IssueCount,
            ProjectCount: metricsData.ProjectCount,
            CableCount: metricsData.CableCount,
            DoneCount: n,
            OngoingProjectCount: metricsData.OngoingProjectCount,
          })
        );
      } catch (e: any) {
        if (e?.response?.status === 401) {
          localStorage.removeItem("token");
          setIsUserLoginTrueORFalse(false);
          history.push("/");
        }
      }
    };
    getNumberOfPages();

    return () => {
      cancelled = true;
    };
  }, []);
  useEffect(() => {
    setLoading(true);
    if (workOrderListData.length > 0) orderData();
  }, [activeFilter, pageSize, activeActivePage, workOrderListData, searchTerm]);

  const setFilter = (value: string) => {
    localStorage.setItem("filter", value);
    setActiveFilter(value);
    setActiveActivePage(1);
  };

  const paginateArray = (
    arr: any[],
    pageSize: number
  ): Array<userWorkOrderListDto[]> => {
    const res = [];
    for (let i = 0; i < arr.length; i += pageSize) {
      res.push(arr.slice(i, i + pageSize));
    }
    return res;
  };

  const orderData = () => {
    let filteredDataArr = workOrderListData;
    if (activeFilter !== "all") {
      filteredDataArr = workOrderListData.filter(
        (wO) => wO.Type === activeFilter || wO.Status === activeFilter
      );
    }
    const pDataArr = paginateArray(
      filteredDataArr
        //allow partial/full id searching
        .filter((v) => {
          if (
            searchTerm === "" ||
            v.WOCaseId.toLowerCase().includes(searchTerm.toLowerCase())
          ) {
            return v;
          }
        }),
      pageSize
    );
    setNumPages(pDataArr.length);
    setPaginatedData(pDataArr[activeActivePage - 1]);
    setLoading(false);
  };

  const setSelectedUserData = async (allData) => {
    setSelectedUserImage([]);
    setSelectedUserFiles(undefined);
    setSelectedCaseId(allData);

    try {
      const images = await getWorkerOrderInfo(allData.WOCaseId);
      let allCaseLogs = images ? images.Data.Logs : [];

      let geomatry = images ? images.Data.Geometry : [];
      setPolygonFromCaseLocation(geomatry);

      setCaseLogs(allCaseLogs);
      const newViewport = {
        ...viewport,
        latitude: images.Data.Center.WGS84[0][0],
        longitude: images.Data.Center.WGS84[0][1],
        zoom: 17,
      };
      setViewport(newViewport);
      setViewportModel(newViewport);
      setMarkerCenter({
        latitude: images.Data.Center.WGS84[0][0],
        longitude: images.Data.Center.WGS84[0][1],
      });
      if (images && images.Data && images.Data && images.Data.network_coords) {
        setNetworkCoords(images.Data.network_coords);
      }
      if (images && images.Data && images.Data && images.Data.network_urls) {
        setNetworkImages(images.Data.network_urls);
      }
      if (images.Data.Document) {
        setSelectedUserFiles(images.Data.Document);
      } else {
        setSelectedUserFiles(undefined);
      }
      if (images.Data.ImageData.length) {
        setSelectedUserImage(images.Data.ImageData);
      } else {
        setSelectedUserImage([]);
      }
    } catch (e: any) {
      if (e?.response?.status === 401) {
        localStorage.removeItem("token");
        setIsUserLoginTrueORFalse(false);
        history.push("/");
      }
      setSelectedUserImage([]);
      setSelectedUserFiles(undefined);
    }
  };

  const handleFileClick = async (fileURL: string) => {
    try {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.href = fileURL;
      a.target = "_blank";
      a.download = "file";
      a.click();
    } catch (error) {
      setErrorMessage("Error While Requesting File!");
      setError(true);
    }
  };

  // const getUserInformation: (uid: string) => string = (uid) => {
  //   let username = "";
  //   if (users.length > 0) {
  //     users.map((item) => {
  //       if (item.id === uid) {
  //         username = item.name.first + " " + item.name.last;
  //       }
  //       return null;
  //     });
  //   }
  //   return username;
  // };

  const renderTooltip = (props) => (
    <Tooltip
      id={`${
        props.type && props.type === "unassign"
          ? "button-tooltip2"
          : "button-tooltip"
      }`}
      {...props}
    >
      <div style={{ backgroundColor: "black" }}>{props.id}</div>
      <div
        style={{
          backgroundColor: props.status ? handleBgColor(props.status) : "#FFF",
        }}
      >
        <div>
          <strong>Name : </strong>
          {props.name}
        </div>
      </div>
    </Tooltip>
  );

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const downloadImages = (fileURL, index) => {
    fetch(fileURL)
      .then((response) => response.blob())
      .then((blob) => {
        if (selectedCaseId) {
          var link = window.document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = selectedCaseId["id"] + "-" + index + 1;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
  };

  //assign case and add logs
  // const assigneCaseToFieldTech = (fieldTech, workOrder) => {
  //   let workerID = workOrder.id;
  //   let fieldTechId = fieldTech.id;
  //   let assignee = fieldTech.name.first + " " + fieldTech.name.last;

  //   const allLogs = workOrder && workOrder.case_log ? workOrder.case_log : [];

  //   let updateLogs = {
  //     time: moment().format("L , LTS"), //10/20/2021, 07:29:20
  //     activity: `Ärendet ${workerID} har tilldelats till ${assignee}`,
  //     responsible: auth.uid,
  //   };

  //   allLogs.push(updateLogs);

  //   firestore
  //     .collection("workorders")
  //     .doc(workerID)
  //     .update({
  //       assignee: fieldTechId,
  //       status: "assigned",
  //       case_log: allLogs,
  //     })
  //     .then((data) => {
  //       fetchTableDataAfterAssignee();
  //     });
  // };

  //unassign case and add logs
  // const unAssigneCaseToFieldTech = (workerID, WO) => {
  //   setShowSpinner(true);

  //   const allLogs = WO && WO.case_log ? WO.case_log : [];

  //   let updateLogs = {
  //     time: moment().format("L , LTS"), //10/20/2021, 07:29:20
  //     activity: `Ärendet ${workerID} har ändrats till otilldelad`,
  //     responsible: auth.uid,
  //   };

  //   allLogs.push(updateLogs);

  //   firestore
  //     .collection("workorders")
  //     .doc(workerID)
  //     .update({
  //       assignee: "",
  //       status: "unassigned",
  //       case_log: allLogs,
  //     })
  //     .then((data) => {
  //       fetchTableDataAfterAssignee();
  //     });
  // };

  // const fetchTableDataAfterAssignee = () => {
  //   setLoading(true);
  //   setShowSpinner(true);

  //   const d = [];

  //   firestore
  //     .collection("projectinquiry")
  //     .get()
  //     .then((data) => {
  //       data.docs.map((t, index) => {
  //         d.push({
  //           ...t.data(),
  //           id: t.id,
  //         });
  //         return null;
  //       });
  //     });

  //   firestore
  //     .collection("workorders")
  //     .get()
  //     .then((data) => {
  //       data.docs.map((t, index) => {
  //         d.push({
  //           ...t.data(),
  //           id: t.id,
  //         });
  //         return null;
  //       });

  //       setTableData(d);
  //       setLoading(false);
  //       setShowSpinner(false);
  //     });
  // };
  // const displayDropDown = (info) => {
  //   const { v, index } = info;
  //   return (
  //     <DropdownItems key={index} show={showTekis === index}>
  //       {users &&
  //         users.map((data, i) => {
  //           if (data.role === "fieldtechnician") {
  //             let firstName =
  //               data && data.name && data.name.first ? data.name.first : "";
  //             let lastName =
  //               data && data.name && data.name.last ? data.name.last : "";
  //             return (
  //               <DropdownItem
  //                 key={i}
  //                 onClick={(e) => {
  //                   e.preventDefault();
  //                   e.stopPropagation();
  //                   setShowSpinner(true);
  //                   assigneCaseToFieldTech(data, v);
  //                 }}
  //                 css={`
  //                   justify-content: ${i === 0
  //                     ? "space-between"
  //                     : "flex-start"};
  //                   border-bottom: ${i === 0
  //                     ? "1px solid " + veryLightGrey
  //                     : ""};
  //                   flex-wrap: no-wrap;
  //                 `}
  //               >
  //                 <Sect>{`${firstName} ${
  //                   lastName ? lastName.substring(0, 1) + ".." : ""
  //                 }`}</Sect>
  //                 {i === 0 && <IconButton src={Carret} />}
  //               </DropdownItem>
  //             );
  //           }
  //         })}
  //     </DropdownItems>
  //   );
  // };

  // const caseStatusState = (status: string, case_log: any) => {
  //   if (status.toLowerCase() === "done")
  //     return moment(case_log[case_log.length - 1].time).format(
  //       "DD MMM YYYY HH:mm:ss"
  //     );
  //   else if (
  //     status.toLowerCase() === "assigned" ||
  //     status.toLowerCase() === "issue"
  //   )
  //     return "Utsättning pågår";
  //   else if (status.toLowerCase() === "unassigned") return "Väntar utsättning";
  //   else if (status.toLowerCase() === "cancelled") return "Ärendet är avbruten";
  // };

  // const displayData = filteredData ? filteredData : tableData;
  const displayData = tableData;
  // workorders table headers
  const COLUMNS = [
    {
      Header: "ID",
    },
    {
      Header: "Typ",
    },
    {
      Header: "Plats",
    },
    {
      Header: "Startdatum",
    },
    {
      Header: "Förfrågare",
    },
    {
      Header: "Utsättningsdatum",
    },
    {
      Header: "Tekniker",
    },
    {
      Header: "Status",
    },
  ];

  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => COLUMNS, []);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useFilters
  );

  const {
    getTableProps,
    // getTableBodyProps,
    // getHeaderGroupProps,
    headerGroups,
    // rows,
    // prepareRow,
  } = tableInstance;

  const _onChange: React.ChangeEventHandler = (e) => {
    e.preventDefault();
    const target = e.target as HTMLInputElement;
    setSearchTerm(target?.value);
  };

  const onChangeWorkOrderStatus = async (WOCaseId, newStatus) => {
    const updatedStatus = await updateWorkOrderStatus(WOCaseId, newStatus);
    //console.log("UPDATED", updatedStatus);
    if (updatedStatus) {
      if (filteredData) {
        setFilteredData(
          filteredData.map((value) => ({
            ...value,
            Status: WOCaseId === value.WOCaseId ? newStatus : value.Status,
          }))
        );
      }
      if (tableData) {
        setTableData(
          tableData.map((value) => ({
            ...value,
            Status: WOCaseId === value.WOCaseId ? newStatus : value.Status,
          }))
        );
      }
      if (selectedCaseId) {
        setSelectedCaseId({
          ...selectedCaseId,
          Status:
            WOCaseId === selectedCaseId.WOCaseId
              ? newStatus
              : selectedCaseId.Status,
        });
      }
      return true;
    }
  };
  const PaginationA = () => {
    const pages = [];
    for (let index = 1; index <= numPages; index++) {
      pages.push(index);
    }

    const displayPages = [];

    if (numPages <= 7) {
      displayPages.push(...pages);
    } else {
      const currentPage = activeActivePage;
      let startIndex = currentPage - 3;
      let endIndex = currentPage + 3;

      if (startIndex < 1) {
        endIndex += Math.abs(startIndex) + 1;
        startIndex = 1;
      }

      if (endIndex > numPages) {
        startIndex -= endIndex - numPages;
        endIndex = numPages;
      }

      if (startIndex > 1) {
        displayPages.push(1);
        if (startIndex > 2) {
          displayPages.push("...");
        }
      }

      for (let i = startIndex; i <= endIndex; i++) {
        displayPages.push(i);
      }

      if (endIndex < numPages) {
        if (endIndex < numPages - 1) {
          displayPages.push("...");
        }
        displayPages.push(numPages);
      }
    }
    return (
      <>
        <PaginationItem
          onClick={() => setActiveActivePage(activeActivePage - 1)}
        >
          &laquo;
        </PaginationItem>
        {displayPages.map((p) => (
          <PaginationItem
            hoverColor={p === "..."}
            onClick={() => {
              if (p === "...") return;
              setActiveActivePage(p);
            }}
            key={p}
            isActive={activeActivePage === p}
          >
            {p}
          </PaginationItem>
        ))}
        <PaginationItem
          onClick={() => setActiveActivePage(activeActivePage + 1)}
        >
          &raquo;
        </PaginationItem>
      </>
    );
  };

  function PageDropdown() {
    return (
      <Dropdown
        style={{
          border: "0.5px solid #01393F",
          borderRadius: "5px",
          padding: "0, 10px,0, 10px",
        }}
        className="d-inline mx-2"
      >
        <Dropdown.Toggle
          variant=""
          style={{
            fontSize: 12,
            textTransform: "uppercase",
            fontWeight: "700",
            letterSpacing: "2px",
            paddingBottom: "0px",
            height: "100%",
          }}
          id="dropdown-autoclose-inside"
        >
          Sidstorlek&nbsp;
        </Dropdown.Toggle>

        <Dropdown.Menu
          style={{
            fontSize: 12,
          }}
        >
          <Dropdown.Item
            onClick={() => {
              setPageSize(20);
              setActiveActivePage(1);
            }}
          >
            {" "}
            20{" "}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setPageSize(50);
              setActiveActivePage(1);
            }}
          >
            {" "}
            50{" "}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setPageSize(100);
              setActiveActivePage(1);
            }}
          >
            {" "}
            100{" "}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setPageSize(200);
              setActiveActivePage(1);
            }}
          >
            {" "}
            {200}{" "}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  const handleCloseExport = () => {
    setShowExport(false);
    setExportFrom("");
    setExportTo("");
    setDownloadingLoader(false);
  };
  const handleShowExport = () => setShowExport(true);

  const downloadXlsxFile = async () => {
    try {
      let temp_arr = [];
      const filterdArr = workOrderListData.filter((wo) => {
        const woDate = wo.WOCaseId.slice(0, 8);
        const filterOnType =
          exportChoices.all ||
          (wo.Type === "cable indication" && exportChoices.cable) ||
          (wo.Type === "project" && exportChoices.project);
        if (
          filterOnType &&
          moment(woDate).format("YYYYMMDD") >=
            moment(exportFrom).format("YYYYMMDD") &&
          moment(woDate).format("YYYYMMDD") <=
            moment(exportTo).format("YYYYMMDD")
        ) {
          return wo;
        }
      });
      let user_org = localStorage.getItem("user_org")
        ? localStorage.getItem("user_org")
        : "";
      for (const index of filterdArr) {
        let t_arr = {
          ID: index.WOCaseId,
          Typ: handleGraphLanguages(index.Type) ?? "",
          Plats: index.Location,
          Startdatum: moment(index.StartDate).format("DD MMM YYYY"),
          Förfrågare: index.IquirerEmail,
          Uppdragstagaren: index.Assignee,
          Utsättningsdatum:
            index.StatusUpdatedOn ??
            moment(index?.StatusUpdatedOn).format("DD MMM YYYY"),
          Status: swedishIt(index.Status, index.InquiryType) ?? index.Status,
        };
        temp_arr.push(t_arr);
      }

      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(temp_arr);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, `Invoice ${user_org}${fileExtension}`);
      handleCloseExport();
    } catch (e: any) {
      handleCloseExport();
      if (e?.response?.status === 401) {
        localStorage.removeItem("token");
      }
    }
  };

  const renderTooltipExport = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Exportera som excel fil
    </Tooltip>
  );

  //else display workorders
  if (displayData.length > 0 || workOrderListData.length > 0) {
    return (
      <Box>
        <ImageSlider
          modalIsOpen={modalIsOpen}
          afterOpenModal={afterOpenModal}
          closeModal={closeModal}
          customStyles={customStyles}
          imageIndex={imageIndex}
          selectedUserImage={selectedUserImage}
          downloadImages={downloadImages}
        />
        <Alert
          setError={setError}
          setErrorMessage={setErrorMessage}
          showError={showError}
          errorMessage={errorMessage}
        />
        {/* <HorizontalFlex css="height: 100%"> */}

        <Container
          // style={{
          //   alignItems: "center",
          //   height: "100%",
          // }}
          fluid
        >
          <Grid container>
            <Grid item xs={12} md={selectedCaseId ? 8 : 12}>
              <Box style={{ padding: 0, height: "100%", overflow: "auto" }}>
                <HorizontalFlex
                  css={
                    "flex: 1; margin: 20px 0; justify-content: space-between; padding: 0 45px"
                  }
                >
                  <Dropdown
                    style={{
                      border: "0.5px solid #01393F",
                      borderRadius: "5px",
                    }}
                    className="d-inline"
                  >
                    <Dropdown.Toggle
                      variant=""
                      style={{
                        fontSize: 12,
                        // textTransform: "uppercase",
                        fontWeight: "700",
                        letterSpacing: "2px",
                        padding: "0",
                        width: "200px",
                        height: "100%",
                      }}
                      id="dropdown-autoclose-inside"
                    >
                      {filters.find((f) => f.value === activeFilter)?.label}
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      style={{
                        fontSize: 12,
                      }}
                    >
                      {filters.map((filter) => (
                        <Dropdown.Item
                          key={filter.value + filter.label}
                          onClick={() => setFilter(filter.value)}
                        >
                          {" "}
                          {filter.label}{" "}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  <Loader
                    type="ThreeDots"
                    color={darkGreen}
                    height={30}
                    width={50}
                    timeout={100000} //3 secs
                    visible={loading}
                  />
                  <HorizontalFlex>
                    <PageDropdown />
                    <DownloadButton
                      onClick={() => {
                        handleShowExport();
                      }}
                    >
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 150 }}
                        overlay={renderTooltipExport}
                      >
                        <PrefixIcon
                          css="margin: 0px; padding:0px;"
                          src={XlsxIcon}
                        />
                      </OverlayTrigger>
                    </DownloadButton>
                    {showExport && (
                      <Modal show={showExport} onHide={handleCloseExport}>
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                          <Form>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Från och med</Form.Label>
                              <Form.Control
                                type="date"
                                placeholder="Ange start datum"
                                value={exportFrom}
                                onChange={(e) => setExportFrom(e.target.value)}
                              />
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Till och med</Form.Label>
                              <Form.Control
                                type="date"
                                placeholder="Ange slutdatum"
                                value={exportTo}
                                onChange={(e) => setExportTo(e.target.value)}
                              />
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Check
                                checked={exportChoices.cable}
                                disabled={exportChoices.all}
                                inline
                                label="Ledningsanvisningar"
                                type="checkbox"
                                value="cable indication"
                                onClick={() =>
                                  setExportChoices({
                                    ...exportChoices,
                                    cable: !exportChoices.cable,
                                  })
                                }
                              />
                              <Form.Check
                                checked={exportChoices.project}
                                disabled={exportChoices.all}
                                inline
                                label="Projekteringar"
                                type="checkbox"
                                value="project"
                                onClick={() =>
                                  setExportChoices({
                                    ...exportChoices,
                                    project: !exportChoices.project,
                                  })
                                }
                              />
                              <Form.Check
                                checked={exportChoices.all}
                                inline
                                label="Alla Ärenden"
                                type="checkbox"
                                value="all"
                                onClick={() =>
                                  setExportChoices({
                                    ...exportChoices,
                                    all: !exportChoices.all,
                                  })
                                }
                              />
                            </Form.Group>
                          </Form>
                        </Modal.Body>
                        <Modal.Footer>
                          <SearchInputContainer
                            css="
                              background-color: #FFFFFF;
                              color: #06B4C8;
                              border: solid 1px #06B4C8;
                              right: 0px;
                              width: 170px;
                              flex-direction: column;

                              "
                          >
                            <Button
                              disabled={
                                exportFrom === "" ||
                                exportTo === "" ||
                                (exportChoices.cable === false &&
                                  exportChoices.project === false)
                              }
                              css="
                      background-color: #FFFFFF;
                      color: #06B4C8;
                      border: solid 1px rgba(0,0,0,0);
                      display: flex;
                      align-items: center;
                      padding:0px;
                      "
                              type="submit"
                              onClick={() => {
                                setDownloadingLoader(true);
                                setTimeout(() => {
                                  downloadXlsxFile();
                                }, 1500);
                              }}
                            >
                              {downloadingLoader ? (
                                <>
                                  <FileLoader /> Laddar ner
                                </>
                              ) : (
                                <>
                                  Ladda ner
                                  <PrefixIcon width={30} src={XlsxIcon} />
                                </>
                              )}
                            </Button>
                          </SearchInputContainer>
                        </Modal.Footer>
                      </Modal>
                    )}
                  </HorizontalFlex>
                </HorizontalFlex>
                <SearchInputContainer
                  padding={0}
                  style={{
                    borderRadius: "5px",
                  }}
                >
                  <PrefixIcon src={Search} />
                  <SearchInput
                    placeholder="Sök ärende.."
                    onChange={_onChange}
                  />
                </SearchInputContainer>
                <Table {...getTableProps()}>
                  <TableHead css="position: sticky;top: 0px;background: white;">
                    {headerGroups.map((headerGroup) => (
                      <TableRow
                        key={headerGroup.id}
                        {...headerGroup.getHeaderGroupProps()}
                        header={true}
                      >
                        {headerGroup.headers.map((column, index) =>
                          selectedCaseId &&
                          (index === 1 || index === 2 || index === 4) ? (
                            <></>
                          ) : (
                            <HeaderSection {...column.getHeaderProps()}>
                              {column.render("Header")}
                            </HeaderSection>
                          )
                        )}
                      </TableRow>
                    ))}
                  </TableHead>

                  {loading && paginatedData.length < 1 ? (
                    <TableBody>
                      <TableRow header={true}>
                        <TableData colSpan={9}>
                          <HorizontalFlex css="margin-left: 40px">
                            Loading...
                          </HorizontalFlex>
                        </TableData>
                      </TableRow>
                    </TableBody>
                  ) : (
                    WorkOrderTableBody(
                      paginatedData,
                      activeActive,
                      setActiveActive,
                      setSelectedUserData,
                      selectedCaseId
                    )
                  )}
                </Table>
                {!loading && (
                  <PaginationDiv css="float: center;">
                    <PaginationA />
                  </PaginationDiv>
                )}
              </Box>
            </Grid>
            {selectedCaseId && (
              <Grid
                item
                xs={12}
                md={4}
                pl={"10px"}
                borderLeft={`1px solid ${veryLightGrey}`}
              >
                <Box
                  style={{
                    position: "sticky",
                    top: "0px",
                    boxSizing: "border-box",
                    alignContent: "center",
                  }}
                >
                  <div className="detailsSidebar">
                    <VerticalFlex
                      id="tabs-container"
                      // css={`
                      // position: relative;
                      // padding: 0 20px;
                      // margin: 20px 0 40px 0;
                      // @media screen and (max-width: 1000px) {
                      //   padding: 0;
                      // }
                      // `}
                    >
                      <Tabs
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        style={{
                          width: "100%",
                          display: "flex",
                          marginTop: 5,
                        }}
                      >
                        <Tab eventKey="lease" title="Karta">
                          <TabContent
                            style={{
                              height: 300,
                              // borderBottom: `1px solid ${veryLightGrey}`,
                            }}
                          >
                            <LeaseInfo
                              {...selectedCaseId}
                              onChangeWorkOrderStatus={onChangeWorkOrderStatus}
                            />
                          </TabContent>
                        </Tab>
                        <Tab eventKey="images" title="Bilder">
                          <TabContent
                            style={{
                              height: 260,
                              marginTop: 30,
                              paddingTop: 20,
                              borderTop: `1px solid ${veryLightGrey}`,
                            }}
                          >
                            <DisplayImages
                              setIsOpen={setIsOpen}
                              setImageIndex={setImageIndex}
                              imageProps={selectedUserImage}
                            />
                          </TabContent>
                        </Tab>
                        <Tab eventKey="files" title="Filer">
                          <TabContent
                            style={{
                              height: 260,
                              marginTop: 30,
                              paddingTop: 20,
                              borderTop: `1px solid ${veryLightGrey}`,
                            }}
                          >
                            <DisplayFiles
                              selectedUserFiles={selectedUserFiles}
                              setError={setError}
                              setErrorMessage={setErrorMessage}
                              handleFileClick={handleFileClick}
                              caseID={selectedCaseId.WOCaseId}
                            />
                          </TabContent>
                        </Tab>
                        <Tab eventKey="log" title="Logg">
                          <TabContent
                            style={{
                              height: "260px",
                              overflowY: "auto",
                            }}
                          >
                            {selectedCaseId && (
                              <DisplayLogs caseLogs={caseLogs} />
                            )}
                          </TabContent>
                        </Tab>
                      </Tabs>
                      <IconButton
                        css={
                          "position: absolute; width: 18px; color: black; height: auto; right: 20px; top: 20px; z-index: 100"
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setSelectedCaseId(null);
                          setActiveActive(null);
                        }}
                        src={CrossAlt}
                      />
                    </VerticalFlex>
                    <div>
                      <br></br>
                    </div>
                    <div>
                      <br></br>
                    </div>
                    <div>
                      <br></br>
                    </div>
                    <div>
                      <br></br>
                    </div>
                    {viewport && key === "lease" && (
                      <MapGl
                        activeCaseId={selectedCaseId?.WOCaseId}
                        showBigMap={setShow}
                        show={show}
                      />
                    )}
                  </div>
                </Box>
              </Grid>
            )}
          </Grid>
        </Container>

        {/* </HorizontalFlex> */}
        {show && (
          <MapModal
            show={setShow}
            setShow={() => setShow(false)}
            viewport={viewport}
            activeCaseId={selectedCaseId?.WOCaseId}
          />
        )}
      </Box>
    );
  } else {
    return (
      <VerticalFlex>
        <Row css="height: 100%">
          <Column css={"padding: 0 "}>
            <HorizontalFlex
              css={
                "flex: 1; justify-content: space-between; margin: 20px 0; padding: 0 40px"
              }
            >
              {/* <FiltersDisplay
                filters={filters}
                active={activeFilter}
                setActiveFilter={(f: string) => setActiveFilter(f)}
                handleColor={handleBgColor}
              /> */}
              <Dropdown
                style={{
                  border: "0.5px solid #01393F",
                  borderRadius: "5px",
                }}
                className="d-inline mx-2"
              >
                <Dropdown.Toggle
                  variant=""
                  style={{
                    fontSize: 12,
                    // textTransform: "uppercase",
                    fontWeight: "700",
                    letterSpacing: "2px",
                    paddingTop: "9px",
                    width: "200px",
                  }}
                  id="dropdown-autoclose-inside"
                >
                  {filters.map((filter) =>
                    filter.value === activeFilter ? filter.label : ""
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu
                  style={{
                    fontSize: 12,
                  }}
                >
                  {filters.map((filter) => (
                    <Dropdown.Item
                      key={filter.value}
                      onClick={() => setActiveFilter(filter.value)}
                    >
                      {" "}
                      {filter.label}{" "}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <Loader
                type="ThreeDots"
                color={darkGreen}
                height={30}
                width={50}
                timeout={100000} //3 secs
                visible={loading}
              />
              <PageDropdown />
            </HorizontalFlex>
            <SearchInputContainer
              padding={0}
              style={{
                borderRadius: "5px",
              }}
            >
              <PrefixIcon src={Search} />
              <SearchInput placeholder="Sök ärende.." onChange={_onChange} />
            </SearchInputContainer>
            {loading ? (
              <HorizontalFlex css="margin-left: 40px; justify-content: center">
                Loading...
              </HorizontalFlex>
            ) : (
              <strong style={{ color: "rgba(1,57,63,1)" }}>
                <br></br>Inga aktuella ärende
              </strong>
            )}
          </Column>
        </Row>
      </VerticalFlex>
    );
  }
};

export default WorkOrders;
