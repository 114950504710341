export const handleBgColor = (status: string) => {
  if (status.toLowerCase() === "unassigned") return "#FF8383";
  else if (status.toLowerCase() === "assigned") return "#06B4C8";
  else if (status.toLowerCase() === "issue") return "#FF5050";
  else if (
    status.toLowerCase() === "completed" ||
    status.toLowerCase() === "done" ||
    status.toLowerCase() === "complete"
  )
    return "#42DB89";
  else if (status.toLowerCase() === "cancelled") return "#A9A9A9";
  else if (status.toLowerCase() === "archived") return "#808080";
  else if (status.toLowerCase() === "confidential") return "#BFA700";
  else if (status.toLowerCase() === "") return "#ffff88";
};

export const handleBgColorMarker = (status: string) => {
  if (status.toLowerCase() === "unassigned") return "#FF8383";
  else if (status.toLowerCase() === "assigned") return "#06B4C8";
  else if (status.toLowerCase() === "issue") return "#FF5050";
  else if (
    status.toLowerCase() === "completed" ||
    status.toLowerCase() === "done" ||
    status.toLowerCase() === "complete"
  )
    return "#42DB89";
  else if (status.toLowerCase() === "cancelled") return "#A9A9A9";
  else if (status.toLowerCase() === "archived") return "#808080"; 
  else if (status.toLowerCase() === "") return "#ffff88";
};

export const handleAdminBgColorMarker = (status: string, type: string) => {
  if (type === "project") return "#ffff88";
  if (status.toLowerCase() === "unassigned") return "#FF8383";
  if (status.toLowerCase() === "open") return "#FF8383";
  else if (status.toLowerCase() === "assigned") return "#06B4C8";
  else if (status.toLowerCase() === "issue") return "#FF5050";
  else if (status.toLowerCase() === "done") return "#42DB89";
  else if (status.toLowerCase() === "cancelled") return "#A9A9A9";
  else if (status.toLowerCase() === "archived") return "#808080";
  else if (status.toLowerCase() === "") return "#ffff88";
};

export const handleGraphColorMarker = (status: string) => {
  if (status === "project") return "rgb(255, 255, 136)";
  else if (status === "cable indication") return "rgb(239, 117, 109)";
  else if (status === "planning") return "rgb(66, 219, 137)";
};

export const handleGraphBGColorMarker = (status: string) => {
  if (status === "project") return "rgba(255, 255, 136, 1)";
  else if (status === "cable indication") return "rgba(239, 117, 109, 1)";
  else if (status === "planning") return "rgba(66, 219, 137, 1)";
};

export const handleGraphLanguages = (status: string) => {
  if (status === "cable indication") return "Ledningsanvisning";
  else if (status === "project") return "Projektering";
  else if (status === "collaboration") return "Samordning";
  else if (status === "planning") return "Samhällsplanering";
  else if (status === "emergency ") return "Akut";
  else return status;
};

export const swedishIt = (status: string, WOtype: string) => {
  if (
    WOtype.toLowerCase().includes("cable") ||
    WOtype.toLowerCase().includes("emergency")
  ) {
    if (status.toLowerCase() === "unassigned") return "Otilldelad";
    else if (status.toLowerCase() === "assigned") return "Tilldelad";
    else if (status.toLowerCase() === "issue") return "Avvikelse";
    else if (status.toLowerCase() === "archived") return "Arkiverad";
    else if (
      status.toLowerCase() === "completed" ||
      status.toLowerCase() === "done" ||
      status.toLowerCase() === "complete"
    )
      return "Avslutad";
    else if (status.toLowerCase() === "cancelled") return "Makulerat";
    else
      return WOtype.toLowerCase().includes("cable")
        ? "Ledningsanvisning"
        : "Akutärenden";
  } else if (WOtype.toLowerCase().includes("project")) {
    if (status.toLowerCase() === "unassigned") return "Ej Klar";
    else if (status.toLowerCase() === "archived") return "Arkiverad";
    else if (
      status.toLowerCase() === "completed" ||
      status.toLowerCase() === "done" ||
      status.toLowerCase() === "complete"
    )
      return "Klar";
    else return "Projektering";
  } else if (WOtype.toLowerCase().includes("planning")) {
    if (status.toLowerCase() === "unassigned") return "Ej Klar";
    else if (status.toLowerCase() === "archived") return "Arkiverad";
    else if (
      status.toLowerCase() === "completed" ||
      status.toLowerCase() === "done" ||
      status.toLowerCase() === "complete"
    )
      return "Klar";
    else return WOtype;
  } else if (WOtype.toLowerCase().includes("collaboration")) {
    if (status.toLowerCase() === "unassigned") return "Ej Klar";
    else if (status.toLowerCase() === "archived") return "Arkiverad";
    else if (
      status.toLowerCase() === "completed" ||
      status.toLowerCase() === "done" ||
      status.toLowerCase() === "complete"
    ) {
      return "Klar";
    } else if (status.toLowerCase() === "assigned") return "Tilldelad";
    else if (status.toLowerCase() === "issue") return "Avvikelse";
    else return "Samordning";
  } 
  else return WOtype;
};
